<template lang="pug">
  .sync-provider
    .sync-provider--form
      p.subtitle {{ 'pages.setup_wizard.synchronize_users_message' | translate }}
      .form-group(style="display:flex")
        label(for="sync-provider") {{ 'pages.setup_wizard.provider' | translate }}
        v-select(
          single
          hideBottomMargin
          :disabled="!isSetupPage"
          :options="autoSyncProvider"
          v-model="secretInfo.syncProvider" )
        span.form-text.invalid {{ 'providerId' | errorText }}

    .sync-provider--actions(v-if="secretInfo.syncProvider && secretInfo.syncProvider.id === 'AZURE_AD'")
      .sync-provider--actions__cell
        span.num-of-action 1.
        img.microsoft-svg-setup-data(
            ref="btn"
            src="@/assets/img/microsoft-btn.svg"
            alt="microsoft-sign-in"
            @click="onOpenMicrosoftAuth")
        span.sync-provider--mark(v-if="syncStepsWasDone > 0 || secretInfo.providerConnectData.tenant")
          i.fa.fa-check
      .sync-provider--actions__cell
        span.num-of-action 2.
        v-btn(@click="onShowGroups"
            :disabled="!(!!secretInfo.providerConnectData.tenant)" ) {{ 'pages.setup_wizard.select_groups' | translate }}
        span.sync-provider--mark(v-if="syncStepsWasDone > 1 || hasSelectedGroupsFromServer")
          i.fa.fa-check
      .sync-provider--actions__cell
        span.num-of-action 3.
        v-btn(
          @click="onStartSync"
          :disabled="!(!!secretInfo.providerConnectData.tenant ) || !hasSelectedGroupsFromServer")
          | {{'pages.setup_wizard.sync_users' | translate}}
        span.sync-provider--mark(v-if="syncStepsWasDone > 2")
          i.fa.fa-check

    .sync-provider--actions(v-if="secretInfo.syncProvider && secretInfo.syncProvider.id === 'GOOGLE_CLOUD'")
      .sync-provider--actions__cell
        span.num-of-action 1.
        .sync-provider--actions__col
          input.invisible-input(
            ref="fileInput"
            type="file"
            @change="onFileOpen")
          button.btn.btn-secondary.btn-lg.sync-provider__btn.mb-3(
            :disabled="!isSetupPage"
            @click="$refs.fileInput.click()")
            i.flaticon-upload
            | {{ google.filename || 'ui.labels.upload_config_file' | translate | truncateText(22) }}
          input.form-control.mb-3(
            type="email"
            :placeholder="'ui.labels.admin_email' | translate"
            :disabled="!isSetupPage"
            v-model="google.email")
          v-btn(
            :disabled="!isSetupPage"
            @click="submitGoogleProvider")
            | {{ 'ui.buttons.submit' | translate }}
        span.sync-provider--mark(v-if="syncStepsWasDone > 0")
          i.fa.fa-check
      .sync-provider--actions__cell
        span.num-of-action 2.
        v-btn(
          :disabled="syncStepsWasDone < 1 && isSetupPage"
          @click="onShowGroups")
          | {{'pages.setup_wizard.select_groups' | translate}}
        span.sync-provider--mark(v-if="syncStepsWasDone > 1 || hasSelectedGroupsFromServer")
          i.fa.fa-check
      .sync-provider--actions__cell
        span.num-of-action 3.
        v-btn(
          :disabled="!hasSelectedGroupsFromServer && isSetupPage"
          @click="onStartSync")
          | {{ 'pages.setup_wizard.sync_users' | translate }}
        span.sync-provider--mark(v-if="syncStepsWasDone > 2")
          i.fa.fa-check

    group-modal(
      :show="showGroups"
      :filteredList="filteredGoupList"
      :list="groupList"
      :loaded="loadedGroupsModalContent"
      :error="google.error"
      @on-close="hideGropus"
      @on-apply="onApplyGroups"
      @on-search="onSearch")
    error-modal(
      :show="showErrorModal"
      @on-close="closeErrorModal")

</template>

<script>
import api from '@/api'
import GroupModal from './modals/GroupModal'
import ErrorModal from './modals/ErrorModal'

export default {
  name: 'SyncProviderForm',

  components: {
    GroupModal,
    ErrorModal
  },

  props: {
    secretInfo: Object,
    isSetupPage: Boolean,
    startSync: Function
  },

  async mounted () {
    const { data } = await api.loadSettingsProvider()
    this.autoSyncProvider.push({ name: 'None', id: null })
    for (const key in data) {
      this.autoSyncProvider.push({ name: data[key], id: key })
    }
    const provider = await this.restoreProvider()
    if (provider) {
      this.secretInfo.syncProvider = this.autoSyncProvider
        .find(item => item.id === provider)
    }
    const selectedGroups = await api.loadAzureSelectedGroups()
    this.hasSelectedGroupsFromServer = !!selectedGroups.data.groupIds.length
  },

  data: () => ({
    google: {
      email: null,
      config: null,
      filename: null,
      error: null
    },
    autoSyncProvider: [],
    interval: null,
    loading: false,
    showGroups: false,
    groupList: [],
    filteredGoupList: [],
    loadedGroupsModalContent: false,
    hasSelectedGroupsFromServer: false,
    syncStepsWasDone: 0,
    showErrorModal: false
  }),

  methods: {
    async restoreProvider () {
      const { data } = await api.settings.dataSync.restore()
      return data.provider && data.provider.id
    },

    onOpenMicrosoftAuth () {
      try {
        this.loading = true
        const BASE_URL = process.env.VUE_APP_BASE_URL
        const parsedHref = window.location.href.split('/')
        const hostname = parsedHref[0] + '//' + parsedHref[2]

        const newWin = window.open(`${BASE_URL}/login/oauth/azure/init?redirectUri=${hostname}/setup/data/redirect`,
          'auth', 'width=580, height=680,modal=yes, resizable=no')

        this.interval = setInterval(() => {
          try {
            let params = new URLSearchParams(newWin.location.search)
            let tenant = params.get('tenant')
            if (tenant) {
              if (this.secretInfo.providerConnectData.tenant !== tenant) {
                this.groupList = []
                this.filteredGoupList = []
              }
              this.secretInfo.providerConnectData.tenant = tenant
              newWin.close()
              this.loading = false
              clearInterval(this.interval)
              this.syncStepsWasDone = 1
              this.$emit('on-load-data')
            }

            if (newWin && newWin.closed) {
              this.loading = false
            }
          } catch (e) {
            if (newWin && newWin.closed) {
              this.loading = false
            }
          }
        }, 500)

        newWin.onload = () => {
          if (newWin && newWin.closed) {
            this.loading = false
          }
        }
      } catch (e) {
      }
    },

    onFileOpen (e) {
      const readSingleFile = (e) => {
        const file = e.target.files[0]
        if (!file) {
          return
        }
        this.google.filename = file.name
        const reader = new FileReader()
        reader.onload = (e) => {
          const contents = e.target.result
          this.google.config = JSON.parse(contents)
        }
        reader.readAsText(file)
      }
      readSingleFile(e)
    },

    async submitGoogleProvider () {
      const payload = {
        providerId: 'GOOGLE_CLOUD',
        providerConnectData: {
          config: this.google.config,
          adminEmail: this.google.email
        }
      }
      const { error } = await api.settings.dataSync.store(payload)
      if (!error) {
        this.syncStepsWasDone = 1
      }
    },

    async onShowGroups  () {
      this.loadedGroupsModalContent = false
      this.showGroups = true
      const { error, data, errorData } = await api.loadAzureGroups()
      if (!error) {
        const selectedGroups = await api.loadAzureSelectedGroups()
        for (const i in data) {
          if (selectedGroups.data.groupIds.includes(data[i].id)) {
            data[i].selected = true
          } else {
            data[i].selected = false
          }
        }
        this.groupList = data
        this.filteredGoupList = data
        this.loadedGroupsModalContent = true
      } else {
        this.google.error = errorData.data.message
      }
    },

    hideGropus () {
      this.showGroups = false
    },

    async onApplyGroups () {
      const groupIds = this.groupList.filter(el => el.selected).map(el => el.id)
      const { error } = await api.putAzureGroups({ groupIds })
      if (!error) {
        this.hasSelectedGroupsFromServer = true
        this.hideGropus()
        this.syncStepsWasDone = 2
      }
    },

    async onStartSync () {
      const error = await this.startSync()
      if (!error) {
        this.syncStepsWasDone = 3
      } else {
        this.showErrorModal = true
      }
    },

    onSearch (search) {
      const filtered = []
      for (let i in this.groupList) {
        if (this.groupList[i].name.toLowerCase().includes(search.toLowerCase())) {
          filtered.push(this.groupList[i])
        }
      }
      this.filteredGoupList = filtered
    },

    closeErrorModal () {
      this.showErrorModal = false
    }

  },

  computed: {
    selectedgroupsLength () {
      return this.groupList.filter(el => el.selected).length
    }
  }
}
</script>

<style lang="scss" scoped>

  .invisible-input {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .sync-provider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0 2rem 0;

    &__btn {
      padding: 8px 10px;
      min-width: 215px;

      &:disabled {
        opacity: .2;
        color: #212529 !important;
        background-color: #e1e1ef !important;
      }
    }

    &--form {
      width: 60%;
      margin-bottom: 1.5rem;
      text-align: center;
      display: flex;
      flex-direction: column;

      .form-group {
        align-items: center;

        label {
          padding-right: 2rem;
          margin-bottom: 0rem;
        }
      }
    }

    &--actions {
      width: 95%;
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;

      &__col {
        display: inline-flex;
        flex-direction: column;
      }

      &__cell {
        position: relative;
        margin: 0 0.5rem;
        padding-right: 30px;

        .num-of-action {
          padding: 0 0.75rem;
          font-weight: 600;
        }

        .sync-provider--mark {
          position: absolute;
          right: 15px;
          height: 15px;
          color: $color-primary;
          transform: translate(50%, 50%);
        }
      }
    }
  }
</style>
