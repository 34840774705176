<template lang="pug">
v-dialog(
      width="500"
      :value="show"
      @input="onClose")
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ 'pages.setup_wizard.groups_lists' | translate }}
        p.subtitle {{ 'pages.setup_wizard.please_select_groups_msg' | translate }}
        p.danger(v-if="error") {{ error }}
      v-card-head-toolbar
        v-btn(
          icon
          gray
          @click="onClose") x
    .group-modal--scroll-wrap
      scrollable-panel
        .group-modal--items-wrap(v-if="loaded && list.length")
          div.group-modal--items-wrap__search
            input.form-control(
              type="text"
              v-model="search"
              :placeholder="'pages.administration.filter_by_name' | translate")

          span.active-dark-text(
            v-if="isSelectedAll"
            @click="removeSelection")
            | {{ 'ui.buttons.remove_selection' | translate}}
          span.active-dark-text(
            v-else
            @click="onSelectAll")
            | {{ 'ui.labels.select_all' | translate}}

          v-list-item(
            v-for="item in filteredList"
            :key="item.id"
            @click.native="selectItem(item)")
            v-checkbox(
              :value="item.selected"
              @click="selectItem(item)")
            v-list-item-content
              v-list-item-title {{ item.name}}
        v-list-empty(v-else-if="loaded && !list.length")
          i.icon.icon--sm.icon--empty
          span {{ 'ui.labels.list_is_empty' | translate }}
        .group-modal--items-wrap(v-else)
          group-item-skeleton(:count="3")
    v-card-actions
      span.group-modal--selected_count(v-if="loaded && list.length") {{ selectedMessage }}
      v-btn(
        @click="onClose" text) {{ 'ui.buttons.cancel' | translate }}
      v-btn(
        @click="onApply" :disabled="!selectedLength") {{ 'ui.buttons.apply' | translate }}

</template>

<script>
import ScrollablePanel from '@/components/ui/ScrollablePanel'
import GroupItemSkeleton from './GroupItemSkeleton'

export default {
  name: 'GroupModal',

  components: {
    ScrollablePanel,
    GroupItemSkeleton
  },

  props: {
    show: Boolean,
    list: Array,
    loaded: Boolean,
    error: String,
    filteredList: Array
  },

  data: () => ({
    search: ''
  }),

  methods: {

    onClose () {
      this.$emit('on-close')
    },

    onApply () {
      this.$emit('on-apply')
    },

    selectItem (item) {
      item.selected = !item.selected
    },

    onSelectAll () {
      this.filteredList.forEach(el => { el.selected = true })
    },

    removeSelection () {
      this.list.forEach(el => { el.selected = false })
    }
  },

  computed: {
    selectedLength () {
      return this.list.filter(el => el.selected).length
    },

    isSelectedAll () {
      return this.list.filter(el => el.selected).length === this.list.length
    },

    selectedMessage () {
      return this.$t('pages.setup_wizard.groups_selected', {
        count: this.selectedLength
      })
    }
  },

  watch: {
    async search (value) {
      this.$emit('on-search', value)
    }
  }
}
</script>

<style lang="scss">
.group-modal {
  &--scroll-wrap {
    padding: 2rem 0;
  }

  &--items-wrap {
    padding: 0 2rem;

    &__search {
      padding-bottom: 1rem;
    }

  }

  &--selected_count{
    left: 2rem;
    position: absolute;
  }
}
</style>
