<template lang="pug">
  v-dialog(
    :width="500"
    :value="show"
    @input="closeModal")
    v-card
      v-card-content
        .error-modal__content
          .icon-wrapper
            i.icon.icon--warning-red
          div
            h2.title {{ 'ui.labels.validation_error' | translate }}
            p.subtitle {{ 'groupIds' | errorText }}
      v-card-actions
        v-btn(
          @click="closeModal") {{ 'ui.buttons.close' | translate }}

</template>

<script>

export default {
  name: 'ErrorModal',

  props: {
    show: Boolean
  },

  methods: {
    closeModal () {
      this.$emit('on-close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-modal__content {
    display: flex;
    align-items: center;

    &:not(.error-modal__content) {
      padding-top: 20px;

      .icon-wrapper {
        padding-right: 20px;
      }

      i {
        height: 120px;
        width: 120px;
      }
    }

  }
</style>
